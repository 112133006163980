import {Link} from "react-router-dom";
import * as React from "react";
import {logoutUserAction} from "../actions/userActions";
import {useDispatch} from "react-redux";

export const  Header = () => {
    const dispatch = useDispatch();
    const user_detail = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null;
    return (
        <header className="bg-white	">
            <div className="flex justify-right items-center ">
                <div className="logo w-20 lg:hidden block ">
                    <Link to="/dashboard">
                     
                        <img
                            src="/images/logoSite.png"
                            alt={"Logo"}
                            className="max-w-full mx-auto border-1 rounded-full drop-shadow w-10 "
                        />
                    </Link>
                </div>
                <div className="nav w-full">
                    <ul className="flex justify-end items-center pr-5  ">
                        <li className="lg:hidden visible">
                            {/* <button className="togglemenmob mr-5 text-2xl text-blue-500 ">
                                <i className="fa fa-bars"/>
                            </button> */}

                            <div
                                className=" overflow-auto z-50 sidebar overflow-auto border-r shadow-2xl p-4 pt-16 bg-white border fixed top-0 -left-full h-screen w-60  ">
                                <Link
                                    to={"#"}
                                    className="togglemenumob right-2 top-2 p-2 absolute text-gray-800 bg-gray-200 rounded-full "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    ></svg>
                                </Link>
                                <ul>
                                    <li>
                                        <Link
                                            to={"/dashboard"}
                                            className="p-3  rounded-lg pl-4 flex items-center text-gray-500  hover:bg-blue-400 hover:text-white text-2xl mb-3 dark:bg-blue-900"
                                        >
                                            <i className="fa fa-home w-9 "/>{" "}
                                            <span className="text-base ">Dashboard</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to={"/cleaner"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  text-2xl hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 "
                                        >
                                            <i className="fa fa-users w-9 "/>{" "}
                                            <span className="text-base ">Cleaner</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={"/driver"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  text-2xl hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 "
                                        >
                                            <i className="fa fa-calendar w-9 "/>{" "}
                                            <span className="text-base ">Driver</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={"/appointment"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  text-2xl hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 "
                                        >
                                            <i className="fa fa-comments-o w-9 "/>{" "}
                                            <span className="text-base ">Appointments</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to={"#"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  text-2xl hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 "
                                        >
                                            <i className="fa fa-user-md w-9  "/>{" "}
                                            <span className="text-base ">Services</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to={"/profile"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 text-2xl"
                                        >
                                            <i className="fa fa-cog w-9 "/>
                                            <span className="text-base ">Settings</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={"/profile"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 text-2xl"
                                        >
                                            <i className="fa fa-bell w-9 "/>{" "}
                                            <span className="text-base ">Settings</span>
                                        </Link>
                                    </li>
                                    <li onClick={() => dispatch(logoutUserAction())}>
                                        <button
                                            
                                            className="border p-1 px-3 rounded text-sm bg-white"
                                        >
                                            <i className="fa fa-sign-out mr-2 "/>
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                      
                        <li>
                            <div className="dropdown relative z-30">
                                <div className=" text-black dark:text-white block datatoggle py-2">
                                    <img
                                        src="/images/logoSite.png"
                                        alt={"Logo"}
                                        className="shadow-lg border md:w-8 w-8 bg-white rounded-full  border-white  "
                                    />
                                </div>
                                <div
                                    style={{display: "none"}}
                                    className="slidecontent dropdown-menu absolute right-0 w-72 top-100 text-gray-400 bg-white border-t shadow-md  dark:bg-gray-500"
                                >
                                    <div className="flex p-5">
                                        <div className="profileImg mr-5">
                                            <img
                                                src="/images/logoSite.png"
                                                alt={"Logo"}
                                                className="shadow-lg border md:w-15 w-12 bg-white rounded-full  border-white  "
                                            />
                                        </div>
                                        <div className="profileContent text-left">
                                            <h3 className="text-gray-600 ">
                                               {user_detail ? user_detail.fullName : "N/A"}!
                                            </h3>
                                            <h5 className="text-sm">Safe haven</h5>
                                        </div>
                                    </div>
                                    <div className="flex  border-t items-center  p-2 bg-gray">
                                        <Link to={"/profile"}>
                                            <button className=" p-1 px-3 rounded text-left text-md ">
                                                <i className="fa fa-user mr-2 text-blue-500"/>
                                                Profile
                                            </button>
                                        </Link>                                        
                                    </div>
                                    {/*
                                    <div className="flex border-t items-center bg-gray p-2">
                                        <Link to={""}>
                                            <button className=" p-1 px-3 rounded text-md text-left ">
                                                <i className="fa fa-cog mr-2 text-blue-500" />
                                                Settings
                                            </button>
                                        </Link>
                                    </div>  
                                    */}                                  
                                    <div className="flex  border-t items-center bg-gray p-2">
                                    <button onClick={() => dispatch(logoutUserAction())}
                                            
                                            className=" p-1 px-3 rounded text-md text-left "
                                        >
                                            <i className="fa fa-sign-out mr-2 text-blue-500"/>
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import { dashboardCountAction } from "../../actions/moduleActions";
import {LoadingIndicator} from "../../components/loading-indicator";

export const Dashboard = () => {
 
     const dashboardCountState = useSelector((state) => state.modulesReducer);
     const {  loading, dashboard_count } = dashboardCountState;
     const dispatch = useDispatch();
     let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
     let listUrl = baseUrl + "/auth/dashboard/count";
     useEffect(() => {
       dispatch(dashboardCountAction(listUrl));
     }, [listUrl,dispatch]);

    return (
        <>
        <HelmetProvider>
        <Helmet>
          <title>Safe Haven | Dashboard </title>
        </Helmet>
      </HelmetProvider>
        {loading ? <LoadingIndicator/> : ""}
        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between  ">
        <div className="contentArea w-full">
            <div className="w-100  py-6 md:px-10 px-3  ">
                    <div className="flex grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4  lg:flex-nowrap flex-wrap relative z-20 dashboardCard  params-inheritance-demo">
                       

                        <Link to={"/user"}
                              className="text-left bg-opacity-70 bg-white shadow-md dark:bg-gray-800 rounded-lg p-4 w-full mb-4 h-36 lg:mb-0">
                            <div

                                className="flex justify-between w-full"
                            >
                                <div className="div el1">
                                    <h3 className="text-md text-gray-400 leading-none dark:text-gray-300">
                                        Users
                                    </h3>
                                </div>
                                <div
                                    className="bg-blue-100 p-3 rounded-full text-blue-900 text-xl text-center w-15">
                                    <span className="fa fa-users w-8"/>
                                </div>
                            </div>
                            <h2 className="text-xl font-normal dark:text-gray-300 leading-none mb-2 text-gray-800	 ">
                               {dashboard_count?.users_count}
                            </h2>
                        </Link>
                        <Link to={"/payment"}
                              className="text-left bg-opacity-70 bg-white shadow-md dark:bg-gray-800 rounded-lg p-4 w-full  mb-4 h-36 lg:mb-0">
                            <div

                                className="flex justify-between w-full"
                            >
                                <div className="div el1">
                                    <h3 className="text-md text-gray-400 leading-none dark:text-gray-300">
                                        Payments
                                    </h3>
                                </div>
                                <div
                                    className="bg-blue-100 p-3 rounded-full text-blue-900 text-xl text-center w-15">
                                    <span className="fa fa-credit-card w-8"/>
                                </div>
                            </div>
                            <h2 className="text-xl font-normal dark:text-gray-300 leading-none mb-2 text-gray-800	 ">
                              ${dashboard_count?.total_earning}
                            </h2>
                        </Link>
                        <Link to={"/contact"}
                              className="text-left bg-opacity-70 bg-white shadow-md dark:bg-gray-800 rounded-lg p-4 w-full mb-4 h-36 lg:mb-0">
                            <div

                                className="flex justify-between w-full"
                            >
                                <div className="div el1">
                                    <h3 className="text-md text-gray-400 leading-none dark:text-gray-300">
                                        Contact
                                    </h3>
                                </div>
                                <div
                                    className="bg-blue-100 p-3 rounded-full text-blue-900 text-xl text-center w-15">
                                    <span className="fa fa-phone w-8"/>
                                </div>
                            </div>
                            <h2 className="text-xl font-normal dark:text-gray-300 leading-none mb-2 text-gray-800	 ">
                              {dashboard_count?.contact_us_count}
                            </h2>
                        </Link>

                        <Link to={"/trips"}
                              className="text-left bg-opacity-70 bg-white shadow-md dark:bg-gray-800 rounded-lg p-4 w-full  mb-4 h-36 lg:mb-0">
                            <div

                                className="flex justify-between w-full"
                            >
                                <div className="div el1">
                                    <h3 className="text-md text-gray-400 leading-none dark:text-gray-300">
                                    Trips
                                    </h3>
                                </div>
                                <div
                                    className="bg-blue-100 p-3 rounded-full text-blue-900 text-xl text-center w-15">
                                    <span ><img src="images/destination.png" className="w-8"/></span>
                                </div>
                            </div>
                            <h2 className="text-xl font-normal dark:text-gray-300 leading-none mb-2 text-gray-800	 ">
                              {dashboard_count?.user_trips_count}
                            </h2>
                        </Link>
                       
                    </div>
              
                </div>
        </div>
    </div>
          
    </>
        
    );
}

export default Dashboard;
import axios from 'axios';
import toast from "react-hot-toast";
import jQuery from "jquery";
import {authHeader, handlingNonauthErrors, handlingAuthErrors, destroyTokenDetails,commonCatchBlock} from "../helpers/helpers";
import qs from 'qs';

export const loginUserAction = (values, baseUrl, _form) => dispatch => {

    dispatch({type: 'USER_LOGIN_REQUEST'})
    let frm = jQuery(_form);    
    axios.post(baseUrl, values)
        .then((res) => {
            dispatch({type: 'USER_LOGIN_SUCCESS'})          
            localStorage.setItem('currentUser', JSON.stringify(res.data.userDetail));
            localStorage.setItem('currentToken', JSON.stringify(res.data.userDetail.accessToken));
            toast.success('Login Successfully.', {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                window.location.href = "/dashboard"
            }, 2000)
        })
        .catch((err) => {
             console.log(err);
                dispatch({type: 'USER_LOGIN_FAILED', payload: err})
                if (!err.response) {
                    return toast.error('Something went wrong', {
                        duration: 2000,
                        position: 'top-right',
                    })
                }
                if (err.response.status === 400 || err.response.status === 401) {
                    return toast.error(err.response.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
                let errors = err.response.data;
                frm.find('.parentGroup').removeClass('error');
                frm.find('.help-block').html('');
                let checkFirstEle = 0;
                jQuery.each(errors, function (i, _msg) {
                    let el = frm.find("[name=" + i + "]");
                    if (checkFirstEle === 0) {
                        el.focus();
                        checkFirstEle++;
                    }
                    el.parents('.parentGroup').find('.help-block').addClass('text-red-500')
                    el.parents('.parentGroup').find('.help-block').html(_msg);
                });
            }
        )
}

export const forgotPasswordActions = (values, baseUrl, _form) => dispatch => {

    dispatch({type: 'FORGOT_PASSWORD'})
    let frm = jQuery(_form);
    axios.post(baseUrl, values,)
        .then((res) => {
            dispatch({type: 'FORGOT_PASSWORD_SUCCESS'})
            toast.success('Mail Sent Successfully.', {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                window.location.href = "/login"
            }, 2000)
        })
        .catch((err) => {
                dispatch({type: 'USER_LOGIN_FAILED', payload: err})
                if (!err.response) {
                    toast.error("Network error", {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
                if (err.response.status === 400) {
                    return toast.error(err.response.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
                let errors = err.response.data;
                frm.find('.parentGroup').removeClass('error');
                frm.find('.help-block').html('');
                let checkFirstEle = 0;
                jQuery.each(errors, function (i, _msg) {
                    let el = frm.find("[name=" + i + "]");
                    if (checkFirstEle === 0) {
                        el.focus();
                        checkFirstEle++;
                    }
                    el.parents('.parentGroup').find('.help-block').addClass('text-red-500')
                    el.parents('.parentGroup').find('.help-block').html(_msg);
                });
            }
        )
}

export const resetPasswordActions = (values, baseUrl, _form, token) => dispatch => {

    dispatch({type: 'RESET_PASSWORD'})
    let frm = jQuery(_form);
    axios.post(baseUrl, values, {
        headers: { "Authorization" : "Bearer "+token }
    }).then((res) => {
            dispatch({type: 'RESET_PASSWORD_SUCCESS'})
            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                window.location.href = "/login"
            }, 2000)
        }).catch((err) => {
                dispatch({type: 'USER_LOGIN_FAILED', payload: err})
                if (!err.response) {
                    toast.error("Network error", {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
                if (err.response.status === 400 || err.response.status === 401) {
                    return toast.error(err.response.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
                let errors = err.response.data;
                frm.find('.parentGroup').removeClass('error');
                frm.find('.help-block').html('');
                let checkFirstEle = 0;
                jQuery.each(errors, function (i, _msg) {
                    let el = frm.find("[name=" + i + "]");
                    if (checkFirstEle === 0) {
                        el.focus();
                        checkFirstEle++;
                    }
                    el.parents('.parentGroup').find('.help-block').addClass('text-red-500')
                    el.parents('.parentGroup').find('.help-block').html(_msg);
                });
            }
        )
}

export const logoutUserAction = () => dispatch => {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('currentToken')
    dispatch({type: 'USER_LOGOUT'})
    toast.success("Logout Successfully", {
        duration: 2000,
        position: 'top-right',
    })
    setTimeout(() => {
        window.location.href = '/'
    }, 2000)
}

export const homeDashboardAction = (baseUrl) => dispatch => {
    dispatch({type: 'USER_HOME_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(),

    })
        .then((res) => {
            dispatch({
                type: 'USER_HOME_SUCCESS', payload: {
                    appointmentCount: res.data.appointmentCount,
                    customerCount: res.data.customerCount,
                    driverCount: res.data.driverCount,
                    cleanerCount: res.data.cleanerCount,
                    userName: res.data.userName
                }
            })
        })
        .catch((err) => {
                dispatch({type: 'USER_HOME_FAILED'})
                if (!err.response) {
                    toast.error('Something went wrong', {
                        duration: 2000,
                        position: 'top-right',
                    })
                    destroyTokenDetails();
                    window.location.href = "/"
                    return false
                }
                if (err.response.status === 401) {
                    destroyTokenDetails();
                    window.location.href = "/"
                }
                toast.error(err.response.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })

            }
        )
}

export const userListsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_USERS_LIST_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(),
    }).then((res) => { 
            dispatch({type: 'GET_USERS_LIST_SUCCESS', payload: {data: res.data.appUsersList, totalItems: res.data.totalPages}})
        })
        .catch((err) => { 
                dispatch({type: 'GET_USERS_LIST_FAILED', payload: err})
                if (!err.response) {
                    toast.error("Network error", {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
                if (err.response.status === 401) {
                    destroyTokenDetails();
                    window.location.href = "/";
                }
                if (err.response.status === 400) {
                    return toast.error(err.response.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
            }
        )
}

export const userStatusAction = (baseUrl, listUrl, data) => dispatch => {

    dispatch({type: 'GET_USERS_LIST_REQUEST'})
    axios.post(baseUrl, data, {
        headers: authHeader()
    }).then((res) => { 
            dispatch({type: 'UPDATE_USER_STATUS_SUCCESS'})
            if (res.data.status) {
                window.location.reload();
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                });
            } else {
                toast.error(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                });
            }

            dispatch({type: 'GET_USERS_LIST_REQUEST'});
            dispatch(userListsAction(listUrl));
        }).catch((err) => {
                dispatch({type: 'UPDATE_USER_STATUS_FAILED'})
                if (err.response.status === 401) {
                    destroyTokenDetails()
                    window.location.href = "/"
                }
                toast.error(err.response.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }
        )
}

export const profileUpdate = (baseUrl, method, _form,formData) => dispatch => {

    dispatch({type: 'PROFILE_UPDATE'});
    let frm = jQuery(_form);
    axios.post(baseUrl, formData, {
        headers: authHeader()
    }).then((res) => { 
            dispatch({type: 'PROFILE_UPDATE_SUCCESS'})
            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                window.location.href = "/dashboard"
            }, 2000)
        }).catch((err) => {  
                dispatch({type: 'USER_LOGIN_FAILED', payload: err})
                commonCatchBlock(err, frm);
            }
        )
}

export const getProfileDetailAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_PROFILE_DETAIL_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(),
    }).then((res) => { 
            dispatch({type: 'GET_PROFILE_DETAIL_SUCCESS', payload: {data: res.data.userProfileDetail}})
        })
        .catch((err) => { 
                dispatch({type: 'GET_PROFILE_DETAIL_FAILED', payload: err})
                if (!err.response) {
                    toast.error("Network error", {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
                if (err.response.status === 401) {
                    destroyTokenDetails();
                    window.location.href = "/";
                }
                if (err.response.status === 400) {
                    return toast.error(err.response.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    });
                }
            }
        )
}
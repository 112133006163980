import * as React from 'react';
import {Link, useLocation, useParams, useSearchParams,NavLink} from "react-router-dom";
import {contactsListAction,contactDeleteAction} from "../../actions/moduleActions";
import {useDispatch, useSelector} from "react-redux";
import {LoadingIndicator} from "../../components/loading-indicator";
import Pagination from "../../components/inc/pagination";
import {useEffect, useState} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";

export function Contact() {

     const getAllContactsState = useSelector(state => state.modulesReducer);
     const {loading, contacts_list, totalItems} = getAllContactsState;
     const baseUrl = process.env.REACT_APP_LOCAL_API_URL;
     const dispatch = useDispatch();
     const [searchParams, setSearchParams] = useSearchParams();
     const searchTerm = searchParams.get('name') || '';
     const pageTerm = searchParams.get('page') || '';
     const [page, setPage] = useState(pageTerm ? pageTerm : 1);
     const [pages, setPages] = useState(1);
     const query = new URLSearchParams();
     const search = useLocation().search;
     const name = new URLSearchParams(search).get('name');
     const listUrl = baseUrl + "/auth/contacts-list/";
     const secondaryUrl = baseUrl + `/auth/contacts-list/?page=${page}`;
     const pageUrl = (searchParams.get('name')) ? baseUrl + `/auth/contacts-list/?name=${searchTerm}&page=${page}` : secondaryUrl;
   
     useEffect(() => {
         setSearchParams({name, page});
         if (searchTerm !== 'null')
             query.set("name", searchTerm)
         query.set("page", page)
         let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl;
         dispatch(contactsListAction(queryUrl))
     }, [page]) 

     useEffect(() => {
         if (contacts_list?.length > 0) {
             setPages(totalItems)
         } else {
             if (contacts_list !== undefined)
                 setPages(1)
         }
     }, [contacts_list, page, totalItems]) 

     function handleUserSearch(e) {
         const name = e.target.value;
         if (name) {
             setSearchParams({name, page});
             query.set("name", name);
         } else {
             setSearchParams({page});
         }
         setPage(1);
         query.set("page", 1);
         let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl;
         dispatch(contactsListAction(queryUrl));
     }

     async function handleRecordDelete(contactId) {
         const data = { 'userQueryId': contactId };
         let url = baseUrl + "/auth/remove/user-query";
         dispatch(contactDeleteAction(url, pageUrl, data));
     }     

     return (
        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between">
        <HelmetProvider>
        <Helmet>
          <title>Safe Haven | Contact List </title>
        </Helmet>
        </HelmetProvider>
        {loading ? <LoadingIndicator/> : ""}
        <div className="contentArea w-full ">
           <div className="w-100 py-6 md:px-10 px-3 ">
                <h2 className="text-2xl mb-3 font-normal  ">
                Contact List
                </h2>
                <div className="flex justify-between items-center mb-6 flex-wrap lg:flex-nowrap">
                    <div className="xl:w-1/3 lg:w-1/2 md:w-1/2 lg:mb-0 mb-2 w-full">
                        <input
                            type="text"
                            
                            value={(searchTerm !== 'null') ? searchTerm : ''}
                            onChange={handleUserSearch}
                            className="shadow appearance-none border-0 rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Search by user name..."
                        />
                    </div>
                </div>                
                <div className="card">
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto tr-even:bg-grey-light striped w-full">
                        <thead className="thead-dark border-b">
                            <tr className="text-gray-600">
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">User Name</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Email Address</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Mobile No</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Subject/Concern</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Message</button></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts_list?.length > 0 ? (
                                contacts_list.map((contact, index) => {
                                return (                        
                            <tr className="border-b text-gray-500" data-index="0">
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{contact?.name}</td>
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{contact?.email}</td>
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{contact?.phoneCode}{contact?.phoneNumber}</td>
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{contact?.subject}</td>
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500 tdMaxWidth">{contact?.message}</td>
                                <td className="px-4 py-2">
                                <Link
                                    title="view"
                                    to={"/contact/view/"}
                                    state={{contact: contact}}
                                    className="p-1 pl-4 mr-1 inline-block pr-4 bg-white border border-blue-400 rounded text-blue-800 font-medium">
                                    <i className="fa fa-eye"/>
                                </Link>
                                <button
                                    onClick={() => handleRecordDelete(contact.id)}
                                    title="delete" className="p-1 pl-4 pr-4 inline-block bg-white border border-red-400 rounded text-red-800 font-medium"><i className="fa fa-trash"></i>
                                </button>
                                </td>
                            </tr>
                                       );
                            })
                            ) : (
                                <tr className="border-b">
                                    <td className="py-5 text-center" colSpan="12">
                                        No User Query Found
                                    </td>
                                </tr>
                            )}                                           
                        </tbody>
                    </table>
                         <Pagination page={page} pages={pages} changePage={setPage}/> 
                    </div>
                </div>
            </div>
        </div>
    </div>
       
    );
}
import React from 'react';

import {usePagination, DOTS} from './usePagination';
import './pagination.css';

const Pagination = ({page, pages, changePage}) => {

    // console.log(pages)

    let middlePagination;
    if (pages <= 5 && pages > 1) {
        middlePagination = [...Array(pages)].map((_, idx) => (
            <button key={idx + 1}
                    onClick={() => changePage(idx + 1)}
                    disabled={page === (idx + 1)}
            >
                {idx + 1}
            </button>
        ))
    } else {

        const startValues = Math.floor((page - 1) / 5) * 5
        // console.log(pages, page)
        middlePagination = (
            <>
                {[...Array(5)
                ].map((_, idx) => (
                    <button key={startValues + idx + 1}
                            onClick={() => changePage(startValues + idx + 1)}
                            disabled={page === (startValues + idx + 1)}
                    >
                        {startValues + idx + 1}
                    </button>
                ))
                }
                <button>...</button>
                <button onClick={() => changePage(pages)}>{pages}</button>
            </>
        )

        if (page > 5) {
            if (pages - page >= 5) {
                middlePagination = (
                    <>
                        <button onClick={() => changePage(1)}>1</button>
                        <button>...</button>
                        <button onClick={() => changePage(startValues)}>{startValues}</button>
                        {[...Array(5)
                        ].map((_, idx) => (
                            <button key={startValues + idx + 1}
                                    onClick={() => changePage(startValues + idx + 1)}
                                    disabled={page === (startValues + idx + 1)}
                            >
                                {startValues + idx + 1}
                            </button>
                        ))
                        }
                        <button>...</button>
                        <button onClick={() => changePage(pages)}>{pages}</button>
                    </>
                )
            } else {
                let amountLeft = pages - page + 5
                // console.log("abc", pages - page, pages, page)
                // console.log(startValues, amountLeft, pages, page)
                middlePagination = (
                    <>
                        <button onClick={() => changePage(1)}>1</button>
                        <button>...</button>
                        <button onClick={() => changePage(startValues)}>{startValues}</button>
                        {[...Array(amountLeft)
                        ].map((_, idx) => (
                            <button key={startValues + idx + 1}
                                    style={(pages < startValues + idx + 1) ? {display: "none"} : null}
                                    onClick={() => changePage(startValues + idx + 1)}
                                    disabled={page === (startValues + idx + 1)}
                            >
                                {startValues + idx + 1}
                            </button>
                        ))
                        }
                    </>
                )
            }
        }
    }

    return pages > 1 && (
        <div className="pagination">
            <button className="pagination_prev"
                    onClick={() => changePage(page - 1)}
                    disabled={page === 1}
            >&#171;</button>
            {middlePagination}
            <button className="pagination_next"
                    onClick={() => changePage(page + 1)}
                    disabled={page === pages}
            >&#187;</button>
        </div>
    );
};

export default Pagination;
import React from 'react';
import {Link, useParams} from "react-router-dom";
import {resetPasswordActions} from "../actions/userActions";
import {LoadingIndicator} from "./loading-indicator";
import {useDispatch, useSelector} from "react-redux";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {ModifiedToast} from "./toaster";

const ResetPassword = () => {

    const dispatch = useDispatch();
    const getAllLoginState = useSelector(state => state.userLoginReducer);
    const {loading} = getAllLoginState;
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL+"/auth/reset/password/";
    const {token, email} = useParams();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        let values = {
            password: e.target.elements.password?.value,
            confirmPassword: e.target.password_confirm?.value,
            email: email
        }
        let frm = document.getElementById('resetForm');
        dispatch(resetPasswordActions(values, baseUrl, frm, token));
    };

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>SAfe Haven | Reset Password </title>
                </Helmet>
            </HelmetProvider>
            <ModifiedToast/>
            {loading ? <LoadingIndicator/> : ""}
            <div className=" flex align-center h-screen w-full">
                <div className=" w-full block   ">
                    <div
                        className=" max-w-md m-auto mt-5 bg-white rounded-lg border border-primaryBorder shadow-default py-8 px-8">
                        <div className="text-center mb-2	">
                            <img
                                src="/images/logoSite.png"
                                width="100"
                                className="mx-auto p-3 bg-gray-100 rounded-full"
                            />
                        </div>
                        <h1 className="text-xl font-medium text-gray-700 mb-10 text-center">
                            Reset your password
                        </h1>
                        <form className="text-left" onSubmit={handleFormSubmit} id="resetForm">
                            <div>
                                <label htmlFor="email" className="pb-2 block text-gray-400">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    className={`w-full p-3 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                                    id="password"
                                    placeholder="Your Password"
                                    required
                                />
                                <div className="help-block"></div>
                            </div>
                            <div>
                                <label htmlFor="password" className="pb-2 block text-gray-400">
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    className={`w-full p-3 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                                    id="password_confirm"
                                    placeholder="Confirm Your Password"
                                    required
                                />
                                <div className="help-block"></div>
                            </div>
                            <div className=" mt-6 text-center">
                                <button
                                    className={`bg-blue-500 py-3 font-medium  mx-auto px-3 w-8/12	 text-md text-white mb-2 rounded border border-blue focus:outline-none focus:border-green-dark`}>
                                    Submit
                                </button>
                                <p
                                    className="text-blue-600 text-center font-medium
                        "
                                >
                                    <Link to="/login">Login</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
import {BrowserRouter as Router, Routes, Route, Navigate, Outlet} from "react-router-dom";
import Login from "../components/login";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import Dashboard from "../pages/dashboard/dashboard";
import {User} from "../pages/users/user";
import {UserView} from "../pages/users/userView";
import {Payment} from "../pages/payment/payment";
import {Contact} from "../pages/contact/contact";
import {ContactView} from "../pages/contact/contactView";
import {PolicyContent} from "../pages/policy/policyContent";
import {AddpolicyContent} from "../pages/policy/addpolicyContent";
import {Layout} from "../components/layout";
import {authToken} from "../helpers/helpers";
import {destroyTokenDetails} from "../helpers/helpers";
import {Profile} from "../pages/profile/Profile";
import {Trips} from "../pages/trips/trips";
import {TripsView} from "../pages/trips/tripsView";

const RouterFile = () => (
    <Router>
        <>
            <Routes>
                    <Route element={<RedirectRoute/>}>
                           <Route path="/" element={<Login/>} exact/>
                           <Route path="/login" element={<Login/>} exact/>
                           <Route path="/forgot-password" element={<ForgotPassword/>} exact/>
                           <Route path="/reset-password/:token/:email" element={<ResetPassword/>} exact/>

                    </Route>
                    <Route element={<ProtectedRoute/>}>
                        <Route path="/" element={<Layout/>}>                    
                             <Route path="dashboard" element={<Dashboard/>} exact/>
                             <Route path="/user" element={<User/>} exact/>
                             <Route path="/user/view/:userId" element={<UserView/>} exact/>
                             <Route path="/payment" element={<Payment/>} exact/>
                             <Route path="/contact" element={<Contact/>} exact/>
                             <Route path="/contact/view" element={<ContactView/>} exact/>
                             <Route path="/policy" element={<PolicyContent/>} exact/>
                             <Route path="/policy/add/" element={<AddpolicyContent/>} exact/>
                             <Route path="/policy/add/:contentId" element={<AddpolicyContent/>} exact/>
                             <Route path="/profile" element={<Profile/>} exact/>
                             <Route path="/trips" element={<Trips/>} exact/>
                             <Route path="/trips/view/" element={<TripsView/>} exact/>
                        </Route>
                    </Route>
            </Routes>
        </>
    </Router>
);

const ProtectedRoute = ({redirectPath = '/'}) => {
    if (!authToken()) {
        return <Navigate to={redirectPath} replace/>;
    }
    return <Outlet/>;
};

const RedirectRoute = ({redirectPath = '/dashboard'}) => {
    if (authToken()) {   
        return <Navigate to={redirectPath} replace/>;
    }
    return <Outlet/>;
};

export default RouterFile;
import {useState, useEffect, useRef, React} from 'react';
import {useNavigate, useParams, Link} from "react-router-dom";
import EditorConent from '../policy/editor';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {Editor} from '@tinymce/tinymce-react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { getPolicyContentAction,staticPageCreateEditAction } from "../../actions/moduleActions";
import {LoadingIndicator} from "../../components/loading-indicator";
import jQuery from "jquery";
import { useDispatch, useSelector } from "react-redux";

export function AddpolicyContent() {

     const navigate = useNavigate();
     const [editor, setEditor] = useState(EditorState.createEmpty()); 
     const editorRef = useRef(null);

     const dispatch = useDispatch();
     const getPolicyContentState = useSelector((state) => state.modulesReducer); 
     const {loading, policy_content} = getPolicyContentState;
     const params = useParams();
     const contentId = params.contentId;
     let baseUrl = process.env.REACT_APP_LOCAL_API_URL; 
     useEffect(() => {
         if (contentId !== undefined) {
             const listUrl = baseUrl+"/content/fetch/policy?type="+contentId;
             dispatch(getPolicyContentAction(listUrl));
         }
     }, []);

     const handlePolicyContentSubmit = async (e) => {
         e.preventDefault(); 
         let awaitMethod = 'post';
         let awaitUrl = (contentId) ? baseUrl+'/content/update/policy' : baseUrl+"/content/add/policy" 

         let frm = document.getElementById('policyContentAdd');
         const editorContent = (editorRef.current) ? editorRef.current.getContent() : null;
         const formData = { 'id': contentId, 'category': jQuery('#policy_type').val(), 'content': editorContent };
         const getUrl = baseUrl+"/content/fetch/policy?type="+contentId;
         dispatch(staticPageCreateEditAction(awaitUrl, awaitMethod, formData, frm , getUrl, contentId));
     };

     return (
        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between">
        <HelmetProvider>
        {loading ? <LoadingIndicator/> : ""}
        <Helmet>
          <title>Safe Haven | Add Policy Content </title>
        </Helmet>
        </HelmetProvider>    
        <div className="contentArea w-full ">
           <div className="w-100 py-6 md:px-10 px-3 ">
           <div className="flex justify-between items-center mb-3 ">
                    <h2 className="text-2xl font-normal  ">Policy Content</h2>
                    <button
                        onClick={() => navigate(-1)}
                        className=" bg-blue-800 p-2 rounded-sm px-3 text-white">
                        <span className=" fa fa-angle-left mr-2"/>Back
                    </button>
                </div>
              
                <div className="card md:p-8 p-4">
                    <div className='formArea'>
                        <form onSubmit={handlePolicyContentSubmit} id="policyContentAdd">
                                <div className="flex lg:flex-nowrap	flex-wrap">
                                    <div className="lg:w w-full ">
                                        <div className="form-group mb-5 ">
                                            <label className="formBlock	mb-2 py-2">Policy Type</label>
                                            <select className="formControl" name="policy_type" id="policy_type">
                                                <option
                                                value='Terms and Conditions'
                                                    selected={
                                                        policy_content &&
                                                        policy_content.category === 'Terms and Conditions'
                                                            ? "selected"
                                                            : null
                                                    } 
                                                >
                                                Terms & Condition
                                                </option>
                                                <option
                                                value='Privacy Policy'
                                                    selected={
                                                        policy_content &&
                                                        policy_content.category === 'Privacy Policy'
                                                            ? "selected"
                                                            : null
                                                    }
                                                >
                                                Privacy Policy	
                                                </option>
                                                <option
                                                value='About App'
                                                    selected={
                                                        policy_content &&
                                                        policy_content.category === 'About App'
                                                            ? "selected"
                                                            : null
                                                    }
                                                >
                                                About App	
                                                </option>
                                            </select>
                                        </div>
                                    </div>                                    
                                </div>
                                <div className="flex lg:flex-nowrap	flex-wrap">
                                    <div className="lg:w w-full ">
                                        <div className="form-group mb-3 ">
                                            <label className="formBlock	mb-2 py-2">Content</label>
                                                    <Editor
                                                         onInit={(evt, editor) => editorRef.current = editor}
                                                         initialValue={policy_content ? policy_content.content : null}
                                                         init={{
                                                             height: 500,
                                                             menubar: false,
                                                             plugins: [
                                                                 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                                                 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                 'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                                                             ],
                                                             toolbar: 'undo redo | blocks | ' +
                                                                 'bold italic forecolor | alignleft aligncenter ' +
                                                                 'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                 'removeformat | help',
                                                             content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                         }}
                                                     />
                                                     <input type="hidden" name="content"/>
                                        </div>
                                    </div>                                    
                                </div>
                                <div className="w-full mt-8">
                                <div className="form-group border-t pt-8 pb-3 ">
                                    <button
                                        type="submit"
                                        className="px-8 rounded py-3 hover:bg-blue-900 sm:w-36 w-full bg-blue-700 text-white sm:mr-2 sm:mb-0 mb-2"
                                    >
                                        Submit
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => navigate(-1)}
                                        className="px-8 rounded py-3 hover:bg-red-500 sm:w-36 w-full bg-red-500 text-white"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
       
    );
}
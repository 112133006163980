export const paymentExportReducer = (state = {}, action) => {
    switch (action.type) {
        case'EXPORT_PAYMENT_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'EXPORT_PAYMENT_SUCCESS':
            return {
                ...state,
                loading: false,
            }
        case'EXPORT_PAYMENT_FAILED':
            return {
                ...state,
                loading: false,
            }                 
        default:
            return state
    }
}
export const modulesReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_DASHBOARD_COUNT_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_DASHBOARD_COUNT_SUCCESS':
            return {
                ...state,
                dashboard_count:action.payload.dashboard_count,
                loading: false,
                success: true
            }
        case'GET_DASHBOARD_COUNT_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }     
        case'GET_CONTACT_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_CONTACT_LIST_SUCCESS':
            return {
                ...state,
                contacts_list:action.payload.data,
                totalItems: action.payload.totalItems,
                loading: false,
                success: true
            }
        case'GET_CONTACT_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_PAYMENT_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_PAYMENT_LIST_SUCCESS':
            return {
                ...state,
                payments_list:action.payload.data,
                totalItems: action.payload.totalItems,
                loading: false,
                success: true
            }
        case'GET_PAYMENT_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }            
        case'GET_EMERG_CONTACTS_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_EMERG_CONTACTS_SUCCESS':
            return {
                ...state,
                emergency_contacts:action.payload.userEmergContacts,
                user_detail:action.payload.userDetail,
                medical_info:action.payload.medicalInfo,
                loading: false,
                success: true
            }
        case'GET_EMERG_CONTACTS_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_POLICY_CONTENT_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_POLICY_CONTENT_SUCCESS':
            return {
                ...state,
                policy_content:action.payload.policyContent,
                loading: false,
                success: true
            }
        case'GET_POLICY_CONTENT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'FETCH_POLICY_CONTENT_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'FETCH_POLICY_CONTENT_SUCCESS':
            return {
                ...state,
                policy_content:action.payload.policyContent,
                loading: false,
                success: true
            }
        case'FETCH_POLICY_CONTENT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }            
        case'CREATE_POLICY_CONTENT_SUCCESS':
            return {
                staticPage: action.payload,
                loading: false
            }                                         
        case'DELETE_CONTACT_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_CONTACT_FAILED':
            return {
                loading: false,
                error: action.payload
            }                   
        default:
            return state
    }
}
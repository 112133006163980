import React from 'react'
import {Link} from "react-router-dom";
import {forgotPasswordActions} from "../actions/userActions";
import {LoadingIndicator} from "./loading-indicator";
import {useDispatch, useSelector} from "react-redux";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {ModifiedToast} from "./toaster";

const ForgotPassword = () => {

    const dispatch = useDispatch();
    const getAllLoginState = useSelector(state => state.userLoginReducer);
    const {loading} = getAllLoginState
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL+"/auth/forgot/password";

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let values = {
            email: e.target.elements.email?.value,
        }

        let frm = document.getElementById('forgotForm');
        dispatch(forgotPasswordActions(values, baseUrl, frm));
    };
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Safe Haven | Forgot Password </title>
                </Helmet>
            </HelmetProvider>
            <ModifiedToast/>
            {loading ? <LoadingIndicator/> : ""}
            <div className=" flex align-center h-screen w-full">
                <div className=" w-full block   ">
                    <div
                        className=" max-w-md m-auto mt-5  bg-white rounded-lg border border-primaryBorder shadow-default py-8 px-8">
                        <div className="text-center mb-2	">
                            <img
                                src="/images/logoSite.png"
                                width="100"
                                className="mx-auto p-3 bg-gray-100 rounded-full"
                            />
                        </div>
                        <h1 className="text-xl font-medium text-gray-700 mb-10 text-center">
                            Forgot your password?
                        </h1>
                        <form className="text-left" onSubmit={handleFormSubmit} id="forgotForm">
                            <div>
                                <label htmlFor="email" className="pb-2 block text-gray-400">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    className={`w-full p-3 text-primary border formControl rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                                    id="email"
                                    placeholder="Your Email"
                                />
                                <div className="help-block"></div>
                            </div>
                            <div className=" mt-6 text-center">
                                <button
                                    className={`bg-blue-500 py-3 font-medium  mx-auto px-3 w-8/12	 text-md text-white mb-2 rounded border border-blue focus:outline-none focus:border-green-dark`}>
                                    Submit
                                </button>
                                <p
                                    className="text-blue-600 text-center font-medium
                        "
                                >
                                    <Link to="/">Login</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
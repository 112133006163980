import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import { emergencyContactsAction } from "../../actions/moduleActions";
import {LoadingIndicator} from "../../components/loading-indicator";

export function UserView() {
     const navigate = useNavigate();
     const params = useParams();
     const userEmergencyContactsState = useSelector((state) => state.modulesReducer);
     const {  loading, emergency_contacts, user_detail, medical_info } = userEmergencyContactsState;
     const dispatch = useDispatch();
     let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
     let listUrl = baseUrl + "/emergency/contact/user/contacts?userId="+params.userId;
     useEffect(() => {
       dispatch(emergencyContactsAction(listUrl));
     }, [listUrl,dispatch]);

     return (
        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between">
        <HelmetProvider>
        <Helmet>
          <title>Safe Haven | User Details </title>
        </Helmet>
        </HelmetProvider>
        {loading ? <LoadingIndicator/> : ""}
        <div className="contentArea w-full ">
           <div className="w-100 py-6 md:px-10 px-3">
                <div className="flex justify-between items-center mb-3">
                    <h2 className="text-2xl font-normal">User Details</h2>
                    <button
                        onClick={() => navigate(-1)}
                        className=" bg-blue-800 p-2 rounded-sm px-3 text-white">
                        <span className=" fa fa-angle-left mr-2"/>Back
                    </button>
                </div>
                <div className="card dark:bg-gray-700 bg-white md:p-8 p-4 mb-3">
                    <div className="flex justify-between items-center flex-wrap">
                        <div className=" w-full	flex flex-wrap">
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-user p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Name</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{ user_detail?.fullName ? user_detail?.fullName : "N/A"}</p> 
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-phone p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Phone Number</h3>
                                                <p className="text-gray-500 text-sm"> </p>                                          
                                                <p className="text-gray-500 text-sm">{user_detail?.countryCode}{user_detail?.mobileNo}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-envelope p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Email</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{user_detail?.email ? user_detail?.email : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-calendar p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Date of Birth</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{user_detail?.dob ? user_detail?.dob : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start ">
                                    <span
                                        className="fa fa-map-marker p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Address</h3>
                                        <p className="text-gray-500 text-sm mb-2">{user_detail?.address ? user_detail?.address : "N/A"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start ">
                                    <span
                                        className="fa fa-image p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight  w-14 h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Profile Image 
                                        </h3>
                                        <p className="text-gray-500 text-sm mb-2">
                                        <img src={ user_detail?.profileImage ? user_detail?.profileImage : '/images/no_image.jpg' } alt="" className="w-9 h-9 rounded-full"/>
                                        </p>
                                    </div>
                                 </div>
                            </div>
                    </div>
                </div>
                <h3 className='py-4 pb-8 text-xl font-medium'>Emergency Contacts</h3>
                <div className="flex justify-between items-center flex-wrap">
                {emergency_contacts?.length > 0 ? (
                    emergency_contacts.map((emergency_contact, index) => {
                        return (
                        <div className=" w-full	flex flex-wrap mb-3">
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-user p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Name</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{emergency_contact?.name}</p> 
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-phone p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Phone Number</h3>
                                                <p className="text-gray-500 text-sm"> </p>                                          
                                                <p className="text-gray-500 text-sm">{emergency_contact?.phoneCode}{emergency_contact?.mobileNo}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-envelope p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Email</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{emergency_contact?.email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-map-marker p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Address</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{emergency_contact?.address}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start ">
                                    <span
                                        className="fa fa-image p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Profile Image 
                                        </h3>
                                        <p className="text-gray-500 text-sm mb-2">
                                        <img src={ emergency_contact?.profileImageUrl ? emergency_contact?.profileImageUrl : '/images/no_image.jpg' } alt="" className="w-9 h-9 rounded-full"/>
                                        </p>
                                </div>
                             </div>
                           </div>
                        </div>
                               );
                            })
                        ) : (
                            <div className=" w-full flex flex-wrap">
                                <center>
                                    No Emergency Contacts Found
                                </center>
                            </div>
                        )}          
                        
                </div>

                <h3 className='py-4 pb-8 text-xl font-medium'>Medical Information</h3>
                <div className="flex justify-between items-center flex-wrap">
                        <div className=" w-full	flex flex-wrap mb-3">
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-user p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Medical History</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{ medical_info?.medical_history ? medical_info?.medical_history : 'N/A'}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-phone p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Prescriptions</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{ medical_info?.prescription ? medical_info?.prescription : 'N/A'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                </div>
            </div>
        </div>
    </div>
    </div>
    );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import { policyContentAction } from "../../actions/moduleActions";
import {LoadingIndicator} from "../../components/loading-indicator";

export function PolicyContent() {

     const navigate = useNavigate();
     const params = useParams();
     const policyContentState = useSelector((state) => state.modulesReducer);
     const {  loading, policy_content } = policyContentState;
     const dispatch = useDispatch();
     let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
     let listUrl = baseUrl + "/content/policy/contents";
     useEffect(() => {
       dispatch(policyContentAction(listUrl));
     }, [listUrl,dispatch]);

     return (
        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between">
        <HelmetProvider>
        <Helmet>
          <title>Safe Haven | Policy Content </title>
        </Helmet>
        </HelmetProvider>
        {loading ? <LoadingIndicator/> : ""}
        <div className="contentArea w-full">
           <div className="w-100 py-6 md:px-10 px-3">
                <div className="flex justify-between items-center mb-3 flex-wrap lg:flex-nowrap">
                    <div className="xl:w-1/3 lg:w-1/2 md:w-1/2 lg:mb-0 mb-2 ">
                    <h2 className="text-2xl font-normal">
                    Policy Content
                </h2>
                    </div>
                    {/*<Link className="btn bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded text-white items-center inline-flex" to="/policy/add"><i className="fa fa-plus mr-2 font-bold"> </i>Add policy</Link>*/}
                  
                </div>

                <div className="card">
                    <div className="overflow-x-auto w-full ">
                    <table className="table-auto tr-even:bg-grey-light striped w-full">
                            <thead className="thead-dark border-b">
                                <tr className="text-gray-600">
                                    <th className="px-4 py-3">
                                        <div className="flex items-center"><button className="font-normal">Category</button></div>
                                    </th>
                                    <th className="px-4 py-3">
                                        <div className="flex items-center"><button className="font-normal">Content</button></div>
                                    </th>                                    
                                    <th className="px-4 py-3"><div className="flex items-center font-normal">Actions</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {policy_content?.length > 0 ? (
                                  policy_content.map((content, index) => {
                                     const policy_content_str = content.content.replace(/<(.|\n)*?>/g, '').substring(0, 300);

                                return (                            
                                <tr className="border-b text-gray-500" data-index="0">
                                    <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{content.category}</td>
                                    <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500 maxWidth" dangerouslySetInnerHTML={ { __html: (policy_content_str)}}></td>
                                    <td className="px-4 py-2">
                                    <Link
                                    title="edit"
                                    to={"/policy/add/"+content.id }
                                        className=" p-1 pl-4 mr-1 inline-block pr-4 bg-white border border-blue-400 rounded text-blue-800 font-medium">
                                        <i className="fa fa-edit"/>
                                    </Link>                                  
                                    </td>
                                </tr>
                                              );
                                    })
                                ) : (
                                    <tr className="border-b">
                                        <td className="py-5 text-center" colSpan="12">
                                            No Policy Content Found
                                        </td>
                                    </tr>
                                )}                                           
                            </tbody>
                        </table>

                        {/* <Pagination page={page} pages={pages} changePage={setPage}/> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
       
    );
}
import * as React from 'react';
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
const moment = require("moment");

export function ContactView() {
     const navigate = useNavigate();
     const location = useLocation();
     const contact_detail = location.state.contact;

     return (

        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between  ">
        <HelmetProvider>
        <Helmet>
          <title>Safe Haven | Contact Details </title>
        </Helmet>
        </HelmetProvider>
        <div className="contentArea w-full ">
           <div className="w-100 py-6 md:px-10 px-3 ">
                <div className="flex justify-between items-center mb-3 ">
                    <h2 className="text-2xl font-normal  ">Contact Details</h2>
                    <button
                        onClick={() => navigate(-1)}
                        className=" bg-blue-800 p-2 rounded-sm px-3 text-white">
                        <span className=" fa fa-angle-left mr-2"/>Back
                    </button>
                </div>
                <div className="card dark:bg-gray-700 bg-white md:p-8 p-4 mb-3">

                    <div className="flex justify-between items-center flex-wrap		">
                        <div className=" w-full	flex flex-wrap ">
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start ">
                                    <span
                                        className="fa fa-user p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium ">Name</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{contact_detail.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5  ">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-envelope p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium ">Email</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{contact_detail.email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start ">
                                    <span
                                        className="fa fa-phone p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight  w-14 h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium ">Phone Number</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{contact_detail?.phoneCode}{contact_detail?.phoneNumber}</p>
                                    </div>
                                </div>
                            </div>
                           <div className="md:w-1/2 lg:w-1/3 w-full mb-5  ">
                                <div className="w-full flex justify-start  ">
                                    <span
                                        className="fa fa-file p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium ">Subject/Concern</h3>
                                        <p className="text-gray-500 text-sm mb-2">{contact_detail.subject}</p>                                  
                                </div>
                            </div>                            
                        </div>
                        <div className="md:w-1/2 lg:w-1/3 w-full mb-5  ">
                                <div className="w-full flex justify-start  ">
                                    <span
                                        className="fa fa-comment p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium ">Message</h3>
                                        <p className="text-gray-500 text-sm mb-2">{contact_detail.message}</p>                                  
                                </div>
                            </div>                            
                        </div>
                        <div className="md:w-1/2 lg:w-1/3 w-full mb-5  ">
                                <div className="w-full flex justify-start  ">
                                    <span
                                        className="fa fa-calendar p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight  w-14 h-14 text-center"></span>
                                    <div className="pl-4"> 
                                        <h3 className="mb-2 text-md font-medium ">Date</h3>
                                        <p className="text-gray-500 text-sm mb-2">{ moment(contact_detail.createdAt).format('DD-MM-YYYY') }</p>                                  
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    );
}

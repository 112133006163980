import {useEffect, useState} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {ModifiedToast} from "../../components/toaster";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LoadingIndicator} from "../../components/loading-indicator";
import {profileUpdate} from "../../actions/userActions";
import {getProfileDetailAction} from "../../actions/userActions";
import jQuery from "jquery";

export function Profile() {

    const navigate = useNavigate();
    const getAllUsersState = useSelector(state => state.userLoginReducer);
    const {loading, currentUser} = getAllUsersState;
    const dispatch = useDispatch();
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    
    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        let awaitMethod = "post";
        let awaitUrl = baseUrl+"/auth/profile/setup";
        let frm = document.getElementById("updateProfile");
        const formData = { 'fullName': jQuery('#fullName').val(),'password': jQuery('#password').val(),'confirm_password': jQuery('#confirm_password').val() };

        let userDetail = {
            "fullName": jQuery('#fullName').val(),
            "email": "jai@yopmail.com"
        }
        localStorage.removeItem('currentUser');
        localStorage.setItem('currentUser', JSON.stringify(userDetail));
        dispatch(profileUpdate(awaitUrl, awaitMethod, frm, formData));
    };

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Safe Haven | Profile Update</title>
                </Helmet>
            </HelmetProvider>
            {loading ? <LoadingIndicator/> : ""}
            <ModifiedToast/>
            <div className="w-100  py-6 md:px-10 px-3 ">
                <h2 className="text-2xl mb-3 font-normal  ">
                    Profile Update
                </h2>
                <div className="card dark:bg-gray-700 bg-white md:p-8 p-4 mb-3">
                    <div className="flex justify-between">
                        <div className="formArea w-full">
                            <form onSubmit={handleProfileUpdate} id="updateProfile" autoComplete="off">
                                <div className="flex lg:flex-nowrap flex-wrap">
                                    <div className="lg:w-1/2 w-full lg:pr-5">
                                        <div className="form-group mb-6 ">
                                            <label className="formBlock mb-2 py-2">First Name *</label>
                                            <input
                                                id="fullName"
                                                name="fullName"
                                                type="text"
                                                placeholder="Enter your name "
                                                className="formControl"
                                                defaultValue={currentUser ? currentUser?.fullName : ""}
                                            />
                                            <div className="help-block"></div>
                                        </div>
                                    </div>
                                    <div className="lg:w-1/2 w-full lg:pr-5">
                                        <div className="form-group mb-6 ">
                                            <label className="formBlock mb-2  py-2">Email</label>
                                            <input
                                                id="email"
                                                name="email"
                                                type="text"
                                                autoComplete="off"
                                                placeholder="Enter your email "
                                                disabled={true}
                                                className="formControl"
                                                defaultValue={currentUser ? currentUser?.email : ""}
                                            />
                                            <div className="help-block"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex lg:flex-nowrap flex-wrap">
                                    <div className="lg:w-1/2 w-full lg:pr-5 ">
                                        <div className="form-group mb-6 ">
                                            <label className="formBlock mb-2 py-2">Password *</label>
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                autoComplete="off"
                                                placeholder="Enter your Password "
                                                className="formControl"
                                            />
                                            <div className="help-block password-error"></div>
                                        </div>
                                    </div>
                                    <div className="lg:w-1/2 w-full ">
                                        <div className="form-group mb-6 ">
                                            <label className="formBlock mb-2  py-2">
                                                Confirm Password *
                                            </label>
                                            <input
                                                id="confirm_password"
                                                name="confirm_password"
                                                type="password"
                                                autoComplete="off"
                                                placeholder="Enter Confirm Password "
                                                className="formControl"
                                            />
                                            <div className="help-block"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full mt-8">
                                    <div className="form-group border-t pt-8 pb-3 ">
                                        <button
                                            type="submit"
                                            className="px-8 rounded py-3 hover:bg-blue-900 sm:w-36 w-full bg-blue-700 text-white sm:mr-2 mb-2 sm:mb-0"
                                        >
                                            Submit
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => navigate("/dashboard")}
                                            className="px-8 rounded py-3 hover:bg-red-500 sm:w-36 w-full bg-red-500 text-white"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
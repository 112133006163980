import {combineReducers} from 'redux';
import {createStore, applyMiddleware, compose} from "redux";
import thunk from 'redux-thunk';
import {userLoginReducer} from './reducers/userReducers';
import {modulesReducer} from './reducers/modulesReducer';
import {paymentExportReducer} from './reducers/paymentExportReducer';
import {tripsReducer} from './reducers/tripsReducer';


const finalReducer = combineReducers({
    userLoginReducer: userLoginReducer,
    modulesReducer: modulesReducer,
    paymentExportReducer: paymentExportReducer,
    tripsReducer: tripsReducer
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
const currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null

const initialState = {
    userLoginReducer: {currentUser: currentUser, currentToken: currentToken}
}

const store = createStore(finalReducer, initialState, composeEnhancers(
    applyMiddleware(thunk)
))

export default store
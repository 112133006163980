export const tripsReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_TRIPS_LIST_REQUEST':
            return {
                loading: true,
            }
        case'GET_TRIPS_LIST_SUCCESS':
            return {
                ...state,
                trips: action.payload.data,
                loading: false,
                totalItems: action.payload.totalItems
            }
        case'GET_TRIPS_LIST_FAILED':
            return {
                loading: false,
                error: action.payload
            }            
        default:
            return state
    }
}
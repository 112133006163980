export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case'USER_LOGIN_REQUEST':
            return {
                ...state,
                loading: true,
            }

        case'FORGOT_PASSWORD':
            return {
                ...state,
                loading: true,
            }
        case'FORGOT_PASSWORD_SUCCESS':
            return {
                loading: false,
            }
        case'RESET_PASSWORD':
            return {
                ...state,
                loading: true,
            }
        case'RESET_PASSWORD_SUCCESS':
            return {
                loading: false,
            }
        case'USER_LOGIN_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true
            }
        case'USER_LOGIN_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'USER_LOGOUT':
            return {
                ...state,
            }
        case'USER_HOME_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'USER_HOME_SUCCESS':
            return {
                ...state,
                appointmentCount: action.payload.appointmentCount,
                loading: false,
                customerCount: action.payload.customerCount,
                driverCount: action.payload.driverCount,
                cleanerCount: action.payload.cleanerCount,
                userName: action.payload.userName
            }
        case'USER_HOME_FAILED':
            return {
                ...state,
                loading: false,
            }
        case'GET_USERS_LIST_REQUEST':
            return {
                loading: true,
            }
        case'GET_USERS_LIST_SUCCESS':
            return {
                ...state,
                users: action.payload.data,
                loading: false,
                totalItems: action.payload.totalItems
            }
        case'GET_USERS_LIST_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'UPDATE_USER_STATUS_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_USER_STATUS_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'PROFILE_UPDATE':
            return {
                ...state,
                loading: true,
            }
        case'PROFILE_UPDATE_SUCCESS':
            return {
                ...state,
                loading: false,
            }  
        case'PROFILE_UPDATE_FAILED':
            return {
                loading: false,
                error: action.payload
            }  
        case'GET_PROFILE_DETAIL_REQUEST':
            return {
                loading: true,
            }
        case'GET_PROFILE_DETAIL_SUCCESS':
            return {
                ...state,
                userProfileDetail: action.payload.data,
                loading: false
            }
        case'GET_PROFILE_DETAIL_FAILED':
            return {
                loading: false,
                error: action.payload
            }                   
        default:
            return state
    }
}
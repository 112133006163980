import * as React from 'react';
import {Link, useLocation, useParams, useSearchParams,NavLink} from "react-router-dom";
import {tripListAction} from "../../actions/moduleActions";
import {useDispatch, useSelector} from "react-redux";
import {LoadingIndicator} from "../../components/loading-indicator";
import Pagination from "../../components/inc/pagination";
import {useEffect, useState} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
const moment = require("moment");

export function Trips() {

     const getAllTripState = useSelector(state => state.tripsReducer);
     const {loading, trips, totalItems} = getAllTripState;
     const baseUrl = process.env.REACT_APP_LOCAL_API_URL;
     const dispatch = useDispatch();
     const [searchParams, setSearchParams] = useSearchParams();
     const searchTerm = searchParams.get('name') || '';
     const pageTerm = searchParams.get('page') || '';
     const [page, setPage] = useState(pageTerm ? pageTerm : 1);
     const [pages, setPages] = useState(1);
     const query = new URLSearchParams();
     const search = useLocation().search;
     const name = new URLSearchParams(search).get('name');
     const listUrl = baseUrl + "/trip/list/";
     const secondaryUrl = baseUrl + `/trip/list/?page=${page}`;
     const pageUrl = (searchParams.get('name')) ? baseUrl + `/trip/list/?name=${searchTerm}&page=${page}` : secondaryUrl;
    
     useEffect(() => {
         setSearchParams({name, page});
         if (searchTerm !== 'null')
             query.set("name", searchTerm)
         query.set("page", page)
         let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl;
         dispatch(tripListAction(queryUrl))
     }, [page]) 

     useEffect(() => {
         if (trips?.length > 0) {
             setPages(totalItems)
         } else {
             if (trips !== undefined)
                 setPages(1)
         }
     }, [trips, page, totalItems]) 

     function handleTripSearch(e) {
         const name = e.target.value;
         if (name) {
             setSearchParams({name, page});
             query.set("name", name);
         } else {
             setSearchParams({page});
         }
         setPage(1);
         query.set("page", 1);
         let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl;
         dispatch(tripListAction(queryUrl));
     }

     return (
        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between">
        <HelmetProvider>
        <Helmet>
          <title>Safe Haven | Trips List </title>
        </Helmet>
      </HelmetProvider>
        {loading ? <LoadingIndicator/> : ""}
        <div className="contentArea w-full">
           <div className="w-100 py-6 md:px-10 px-3">
                <h2 className="text-2xl mb-3 font-normal">
                Trips
                </h2>
                <div className="flex sm:justify-between justify-end items-center mb-6 flex-wrap lg:flex-nowrap">
                    <div className="xl:w-1/3 lg:w-1/2 md:w-1/2 lg:mb-0 mb-2 w-full">
                        <input
                            type="text"
                            value={(searchTerm !== 'null') ? searchTerm : ''}
                            onChange={handleTripSearch}
                            className="shadow appearance-none border-0 rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Search by user name..."
                        />
                    </div>
                    {/* <select className="p-2 px-4 w-24 border-0 border rounded float-right bg-white">  
                    <option>  Filter  </option>
                    <option>Ongoing</option>
                    <option>  Finished</option>
                    <option>  Not started</option>
                    </select> */}
                </div>
                <div className="card">
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto tr-even:bg-grey-light striped w-full">
                                <thead className="thead-dark border-b">
                                    <tr className="text-gray-600">
                                        <th className="px-4 py-3">
                                            <div className="flex items-center"><button className="font-normal">Username</button></div>
                                        </th>
                                        <th className="px-4 py-3">
                                            <div className="flex items-center"><button className="font-normal"> Start Date</button></div>
                                        </th>
                                        <th className="px-4 py-3">
                                            <div className="flex items-center"><button className="font-normal"> Start Time</button></div>
                                        </th>
                                        <th className="px-4 py-3">
                                            <div className="flex items-center"><button className="font-normal">Time to Reach</button></div>
                                        </th>
                                        <th className="px-4 py-3">
                                            <div className="flex items-center"><button className="font-normal">Destination </button></div>
                                        </th>
                                        <th className="px-4 py-3">
                                            <div className="flex items-center"><button className="font-normal">Status</button></div>
                                        </th>
                                        <th className="px-4 py-3"><div className="flex items-center font-normal">Actions</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {trips?.length > 0 ? (
                                      trips.map((trip, index) => {
                                          return (
                                            <tr className="border-b text-gray-500" data-index={index} key={index}> 
                                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{trip.username ? trip.username : "N/A"} </td>
                                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{ trip.startDateTime ? moment(trip.startDateTime).format('MM-DD-YYYY') : ""}</td>
                                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{ trip.startDateTime ? moment(trip.startDateTime).format('h:mm A') : ""}</td>
                                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{ trip.expectedTime } hour</td>
                                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{ trip.destination }</td>
                                                <td className="p-4 border-r">
                                                {trip.tripStatus==1 ? 
                                                   <button title="Ongoing" className="p-1 pl-4 mr-1 inline-block pr-4 bg-blue-500 border border-blue-500 rounded text-white">Ongoing</button>
                                                : 
                                                 trip.tripStatus==2 ? 
                                                   <button title="Finished" className="p-1 pl-4 mr-1 inline-block pr-4 bg-green-500 border border-blue-500 rounded text-white">Finished</button>
                                                : trip.tripStatus==3 ? 
                                                   <button title="Canceled" className="p-1 pl-4 mr-1 inline-block pr-4 bg-red-500 border border-blue-500 rounded text-white">Canceled</button>
                                                : 
                                                   <button title="Incomplete" className="p-1 pl-4 mr-1 inline-block pr-4 bg-blue-500 border border-blue-500 rounded text-white">Incomplete</button>
                                                }
                                                </td>
                                                <td className="px-4 py-2">
                                                <Link
                                                    title="view"
                                                    to={"/trips/view/" }
                                                    state={{trip: trip}}
                                                    className="p-1 pl-4 mr-1 inline-block pr-4 bg-white border border-blue-400 rounded text-blue-800 font-medium">
                                                    <i className="fa fa-eye"/>
                                                </Link>
                                                </td>
                                            </tr>
                                            );
                                        })
                                    ) : (
                                        <tr className="border-b">
                                            <td className="py-5 text-center" colSpan="12">
                                                No trips Found
                                            </td>
                                        </tr>
                                    )}                                    
                                </tbody>
                            </table>
                            <Pagination page={page} pages={pages} changePage={setPage}/> 
                    </div>
                </div>
            </div>
        </div>
    </div>
       
    );
}
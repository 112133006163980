import React, {useState} from "react";
import {Link} from "react-router-dom";
import {logoutUserAction} from "../actions/userActions";
import {useDispatch} from "react-redux";

export const Sidebar=()=> {
    const dispatch = useDispatch();
    
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [dropclick, setdropClick] = useState(false);
    const handleDrop = () => setdropClick(!dropclick);
  

    return (
        <>
            <div className=" text-white my-3 MobileMenu text-center block lg:hidden" onClick={() => handleClick(true)} >
                <i className="fa fa-bars text-2xl"/>
            </div>
       
        <div
            className={click ? "sidebarnav sidebaropen bg-gradient-to-t from-blue-600 to-blue-900 z-10  left-0  lg:visible invisible py-5" : "text-white sidebarnav bg-gradient-to-t from-blue-600 to-blue-900 z-10  left-0  lg:visible invisible py-5"}>
            <div className="logo w-50 mx-auto mb-4">
                <Link to={"/dashboard"}>
                    <img
                        src="/images/logo-white.png"
                        alt={"Logo"}
                        className="max-w-full border-1 mx-auto rounded-full drop-shadow "
                        width="50"
                    />
                </Link>
            </div>
            <div
                className=" text-white my-3 togllefull text-center lg:block hidden"
                onClick={handleClick}
            >
                <i className="fa fa-bars text-2xl lg:block hidden"/>
                <i className="fa fa-close text-3xl lg:hidden block"/>
            </div>
            <ul className={click ? "sidebarulOpen" : "sidebarul"}>
                <li title="Dashboard">
                    <Link
                        to={"/dashboard"}
                        className="p-3  rounded-lg pl-4 flex items-center  hover:text-white text-3xl dark:bg-blue-900"
                    >
                        <i className="fa fa-tachometer   w-8 "/>
                        <span
                            className="text-base ml-2"
                            title="Dashboard"
                            data-tooltip-target="tooltip-default"
                        >
                Dashboard
              </span>
                    </Link>
                </li>

                <li onClick={handleDrop}>
                    <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                        <Link to={"/user"}
                            title="User"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "
                        >
                            <i className="fa fa-users">
                               
                            </i>
                            <span className="text-base ">User List</span>

                            <i className="fa fa-angle-right"/>
                        </Link>
                        {/* <ul className="slidecontent " style={{display: "none"}}>
                            <li>
                                <Link to={"/cleaner/add"}>
                                    Add Cleaner
                                </Link>
                            </li>
                            <li>
                                <a href={"/cleaner/list"}>
                                    Cleaner List
                                </a>
                            </li>
                            <li>
                                <a href={"/cleaner/review"}>
                                    Cleaner Review List
                                </a>
                            </li>
                        </ul> */}
                    </div>
                </li>    
                <li>
                    <Link to={"/trips"}
                        title="Payment"
                        className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "
                    >

                        <i ><img src="images/destination-white.png" className="w-8"/></i>
                        <span className="text-base ">Trips </span>
                        <i className="fa fa-angle-right"/>
                    </Link>
                  
                </li>        
                <li>
                    <Link to={"/payment"}
                        title="Payment"
                        className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "
                    >

                        <i className="fa fa-credit-card  "/>
                        <span className="text-base ">Payment List </span>
                        <i className="fa fa-angle-right"/>
                    </Link>
                  
                </li>
                <li>
                    <Link to={"/contact"}
                        className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "
                    >
                        <i className="fa fa-phone "/>
                        <span className="text-base ">Contact Us </span>
                        <i className="fa fa-angle-right"/>
                    </Link>
                </li>
                <li>
                <Link to={"/policy"}
                        title="Masters"
                        className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "
                    >
                        <i className="fa fa-book "/>
                        <span className="text-base ">Policy Content</span>
                        <i className="fa fa-angle-right"/>
                    </Link>
                
                </li>          

                <li onClick={() => dispatch(logoutUserAction())}>
                    <Link
                        title="Logout"
                        to={"#"}
                        className="p-3 pl-4 flex rounded items-center text-white  hover:text-white dark:text-gray-300 text-2xl"
                    >
                        <i className="fa fa-sign-in   "/>{" "}
                        <span className="text-base ">Logout</span>
                    </Link>
                </li>
            </ul>
            <div className=" text-white my-3 togllemin text-center" onClick={() => handleClick(true)} >
                <i className="fa fa-arrow-left text-2xl"/>
            </div>
        </div>
        </>
    );
}

export default Sidebar;
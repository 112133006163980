import * as React from 'react';
import {Sidebar} from "./sideBar";
import {Header} from "./header";
import {Outlet} from "react-router-dom";

export function Layout() {
    return (
        <div>
            <div className="xl:w-full mx-auto min-h-screen relative flex justify-between  ">
                <Sidebar/>
                <div className="contentArea  ">
                    <Header/>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}
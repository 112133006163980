import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from "react-router-dom";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import { emergencyContactsAction } from "../../actions/moduleActions";
import {LoadingIndicator} from "../../components/loading-indicator";
const moment = require("moment");

export function TripsView() {
     const navigate = useNavigate();
     const location = useLocation();
     const trip_detail = location.state.trip;

     return (
        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between">
        <HelmetProvider>
        <Helmet>
          <title>Safe Haven | Trip Details </title>
        </Helmet>
        </HelmetProvider>
        <div className="contentArea w-full ">
           <div className="w-100 py-6 md:px-10 px-3">
                <div className="flex justify-between items-center mb-3">
                    <h2 className="text-2xl font-normal">Trip Details</h2>
                    <button
                        onClick={() => navigate(-1)}
                        className=" bg-blue-800 p-2 rounded-sm px-3 text-white">
                        <span className=" fa fa-angle-left mr-2"/>Back
                    </button>
                </div>
                <div className="card dark:bg-gray-700 bg-white md:p-8 p-4 mb-3">
                    <div className="flex justify-between items-center flex-wrap">
                        <div className=" w-full	flex flex-wrap">
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-user p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Name</h3>
                                                <p className="text-gray-500 text-sm"> </p>
                                                <p className="text-gray-500 text-sm">{trip_detail.username}</p> 
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-calendar p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4"> 
                                        <h3 className="mb-2 text-md font-medium">Start Date</h3>
                                                <p className="text-gray-500 text-sm">{ trip_detail.startDateTime ? moment(trip_detail.startDateTime).format('MM-DD-YYYY') : "N/A"}</p>                                          
                                                
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-clock-o p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Time To Reach </h3>
                                        <p className="text-gray-500 text-sm"> {trip_detail.expectedTime} hour </p>
                                    </div>
                                </div>
                            </div>      
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-clock-o p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">    
                                        <h3 className="mb-2 text-md font-medium">Start Time </h3>
                                        <p className="text-gray-500 text-sm"> { trip_detail.startDateTime ? moment(trip_detail.startDateTime).format('h:mm A') : "N/A"} </p>
                                    </div>
                                </div>
                            </div>   
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start">
                                    <span
                                        className="fa fa-clock-o p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">End Time </h3>
                                        <p className="text-gray-500 text-sm"> { trip_detail.endDateTime ? moment(trip_detail.endDateTime).format('h:mm A') : "N/A"} </p>
                                    </div>
                                </div>
                            </div>                        
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start ">
                                    <span
                                        className="fa fa-map-marker p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight	 w-14	h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Destination </h3>
                                        <p className="text-gray-500 text-sm mb-2">{trip_detail.destination}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 lg:w-1/3 w-full mb-5 ">
                                <div className="w-full flex justify-start ">
                                    <span
                                        className="fa fa-check p-3 bg-blue-100 rounded-full text-blue-900 text-2xl leading-tight  w-14 h-14 text-center"></span>
                                    <div className="pl-4">
                                        <h3 className="mb-2 text-md font-medium">Trip Status
                                        </h3>
                                        <p className="text-gray-500 text-sm mb-2">
                                         {trip_detail.tripStatus==1 ? 
                                                   <span>Ongoing</span>
                                                : 
                                                 trip_detail.tripStatus==2 ? 
                                                   <span>Finished</span>
                                                : trip_detail.tripStatus==3 ? 
                                                   <span>Canceled</span>
                                                : 
                                                   <span>Incomplete</span>
                                         }
                                        </p>
                                    </div>
                                 </div>
                            </div>
                    </div>
                </div>
            
            </div>
        </div>
    </div>
    </div>
    );
}

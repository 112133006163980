import axios from "axios";
import toast from "react-hot-toast";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import jQuery from 'jquery';
// import * as React from "react";

const MySwal = withReactContent(Swal);

export function deleteAnyRecord(sendApi, fetchApi, toUsed) {
    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(sendApi)
                    .then((res) => {
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        setTimeout(() => {
                            getAllList(fetchApi, toUsed).catch((err) => console.log(err))
                        }, 1000)

                    })
                    .catch((err) => {
                            toast.error(err.response.data.message, {
                                duration: 2000,
                                position: 'top-right',
                            })

                        }
                    )
            }

        }
    )
}

export function getAllList(sendApi, toBeUsedState) {
    return axios.get(sendApi)
        .then((res) => {
            toBeUsedState(res.data.list)
        })
        .catch((err) => {
                console.log(err)
            }
        )
}

export function getEditData(sendApi, toBeUsedState) {
    return axios.get(sendApi)
        .then((res) => {
            toBeUsedState(res.data.user)
        })
        .catch((err) => {
                if (err.response.status === 401) {
                    window.location.href = "/"
                }
                console.log(err)
            }
        )
}

export async function saveForm(sendApi, _form, method) {
    let frm = jQuery(_form);
    console.log(frm.serialize())
    try {
        return await axios({
            method: method,
            url: sendApi,
            data: frm.serialize(),
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    } catch (e) {
        return commonErrorBag(frm, e)
    }
}

export async function saveProfile(sendApi, _form, method) {
    try {
        return await axios({
            method: method,
            url: sendApi,
            data: _form,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    } catch (e) {
        console.log(e)
        return commonErrorBag(_form, e)
    }
}

export function commonErrorBag(frm, error) {
    if (error.response.status === 400) {
        return toast.error(error.response.data.message, {
            duration: 2000,
            position: 'top-right',
        });
    }

    let errors = error.response.data;
    frm.find('.form-group').removeClass('error');
    frm.find('.help-block').html('');
    let checkFirstEle = 0;
    jQuery.each(errors, function (i, _msg) {
        let el = frm.find("[name=" + i + "]");
        if (checkFirstEle === 0) {
            el.focus();
            checkFirstEle++;
        }
        el.parents('.form-group').find('.help-block').addClass('text-red-500')
        el.parents('.form-group').find('.help-block').html(_msg);
    });

}

export function authHeader() {
    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    if (currentToken) {
        return {"Authorization" : `Bearer ${currentToken}`}
    } else {
        return {}
    }
}

export function isAdmin() {

    let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    if (currentUser && (currentUser.isAdmin === true)) {
        return true
    } else {
        return false
    }
}

export function authToken() {
    return localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
}

export function destroyTokenDetails() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentToken');
}

export function handlingNonauthErrors(err) {
    if (!err.response) {
        toast.error("Network error", {
            duration: 2000,
            position: 'top-right',
        });
    }
    if (err?.response?.status === 400) {
        toast.error(err.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    if (err?.response?.status === 404) {
        toast.error(err.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export function handlingAuthErrors(err) {
    if (!err.response) {
        destroyTokenDetails();
        toast.error("Network error", {
            duration: 2000,
            position: 'top-right',
        });
        setTimeout(() => {
            window.location.href = "/";
        }, 100)
    }
    if (err.response.status === 401) {
        destroyTokenDetails();
        window.location.href = "/";
    }
    if (err.response.status === 422) {
        toast.error(err.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    if (err.response.status === 400) {
        toast.error(err.response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export function commonCatchBlock(err, frm = false) {
    if (!err.response) {
        return toast.error('Something went wrong', {
            duration: 2000,
            position: 'top-right',
        })
    }

    if (err.response.status === 401) {
        destroyTokenDetails()
        window.location.href = "/"
        return false
    }

    if (err.response.status === 406) {
        return toast.error(err.response.data.message, {
            duration: 2000,
            position: 'top-right',
        });
    }

    if (frm) {
        let errors = err.response.data;
        frm.find('.form-group').removeClass('error');
        frm.find('.help-block').html('');
        frm.find('.password-error').addClass('text-red-500')
        frm.find('.password-error').html(err.response.data.message);
        return toast.error(err.response.data.message, {
            duration: 2000,
            position: 'top-right',
        })
    } else {
        if (err.response?.data?.message)
            toast.error(err.response.data.message, {
                duration: 2000,
                position: 'top-right',
            })
    }
    if (err.response.status === 500) {
        return toast.error(err.response.data.message, {
            duration: 2000,
            position: 'top-right',
        });
    }
}
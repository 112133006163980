import * as React from 'react';
import {Link, useLocation, useParams, useSearchParams,NavLink} from "react-router-dom";
import {paymentListAction,paymentReportDownloadAction} from "../../actions/moduleActions";
import {useDispatch, useSelector} from "react-redux";
import {LoadingIndicator} from "../../components/loading-indicator";
import Pagination from "../../components/inc/pagination";
import {useEffect, useState} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jQuery from "jquery";
const moment = require("moment");

export function Payment() {

     const getAllPaymentState = useSelector(state => state.modulesReducer);
     const {loading, payments_list, totalItems} = getAllPaymentState;
     const baseUrl = process.env.REACT_APP_LOCAL_API_URL;
     const dispatch = useDispatch();
     const [searchParams, setSearchParams] = useSearchParams();
     const searchTerm = searchParams.get('name') || '';
     const pageTerm = searchParams.get('page') || '';
     const startDateTerm = searchParams.get('start_date') || '';
     const endDateTerm = searchParams.get('end_date') || '';
     const [startDateValue, setStartDateValue] = useState(startDateTerm ? startDateTerm : '');
     const [endDateValue, setEndDateValue] = useState(endDateTerm ? endDateTerm : '');
     const [page, setPage] = useState(pageTerm ? pageTerm : 1);
     const [pages, setPages] = useState(totalItems ? totalItems : page);
     const [isLoading, setIsLoading] = useState(loading);
     const query = new URLSearchParams();
     const search = useLocation().search;
     const name = new URLSearchParams(search).get('name');
     const listUrl = baseUrl + "/payment/admin-list/";
     const secondaryUrl = baseUrl + `/payment/admin-list/?page=${page}`;
     const pageUrl = (searchParams.get('name')) ? baseUrl + `/payment/admin-list/?name=${searchTerm}&page=${page}` : secondaryUrl;
     
     useEffect(() => {
         setSearchParams({name, page});
         if (searchTerm !== 'null')
             query.set("name", searchTerm)
         query.set("page", page)
         let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl;
         dispatch(paymentListAction(queryUrl))
     }, [page]) 

     useEffect(() => {
         if (payments_list?.length > 0) {
             setPages(totalItems)
         } else {
             if (payments_list !== undefined)
                 setPages(1)
         }
     }, [payments_list, page, totalItems]) 

     function handleUserSearch(e) {
         const name = e.target.value;
         if (name) {
             setSearchParams({name, page});
             query.set("name", name);
         } else {
             setSearchParams({page});
         }
         setPage(1);
         query.set("page", 1);
         let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl;
         dispatch(paymentListAction(queryUrl));
     } 

     const [showResults, setShowResults] = useState(false);
     const onClickFilter = () => setShowResults(!showResults);

     async function handlePaymentFilter(e) {
        e.preventDefault(); 
        const startDate = moment(document.getElementById('start_date').value).format('YYYY-MM-DD');
        const endDate = moment(document.getElementById('end_date').value).format('YYYY-MM-DD');
        const fromDate = moment(startDateValue).format('YYYY-MM-DD');
        const toDate = moment(endDateValue).format('YYYY-MM-DD');

        if ((fromDate !== undefined || fromDate !== '') &&
            (toDate !== undefined || toDate !== '')) {
            const dS = new Date(startDate);
            const dE = new Date(endDate);
            if (dE < dS) {
                document.getElementById('end_date').value = null;
                setEndDateValue('');
                return toast.error("End Date should be greater than start date.", {
                    duration: 2000,
                    position: 'top-right',
                });
            }
        }

        setStartDateValue(startDateValue);
        setEndDateValue(endDateValue);
        setTimeout(() => {
            let name = '';
            setSearchParams({fromDate, toDate, name, page});
            query.set("startDate", fromDate);
            query.set("endDate", toDate);
            setPage(1);
            query.set("name", '');
            query.set("page", 1);
            setIsLoading(false);
            let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl;
            dispatch(paymentListAction(queryUrl));
            if (payments_list?.length > 0) {
                setPages(totalItems);
            } else {
                setPages(0);
            }
        }, 1500)
     }

     async function handleResetFilter(e) {
         setIsLoading(true);
         e.preventDefault();
         setStartDateValue('');
         setEndDateValue('');
         document.getElementById('start_date').value = '';
         document.getElementById('end_date').value = '';
         window.location.reload();
     }

     const downloadUrl = baseUrl+`/payment/payment-export`;
     async function queryUrlMaking(download = false) {
        const fromDate = moment(startDateValue).format('YYYY-MM-DD');
        const toDate = moment(endDateValue).format('YYYY-MM-DD');
         setSearchParams({fromDate, toDate, name});
         if (searchTerm !== 'null')
            query.set("name", searchTerm)
         if (startDateValue && endDateValue)
            query.set("startDate", fromDate);
            query.set("endDate", toDate);
         if (download)
             return (query.toString()) ? downloadUrl + `?` + query.toString() : downloadUrl;
     }

     async function exportData() {
         let paymentExportUrl = await queryUrlMaking(true);
         dispatch(paymentReportDownloadAction(paymentExportUrl, 'Payments'));
     }

     return (
        <div className="xl:w-full mx-auto min-h-screen relative flex justify-between">
        <HelmetProvider>
        <Helmet>
          <title>Safe Haven | Payments List </title>
        </Helmet>
        </HelmetProvider>
        {loading ? <LoadingIndicator/> : ""}
        <div className="contentArea w-full"> 
           <div className="w-100 py-6 md:px-10 px-3">
                <h2 className="text-2xl mb-3 font-normal  ">
                Payments
                </h2>
                <div className="flex justify-end md:justify-between items-center mb-6 flex-wrap lg:flex-nowrap">
                    <div className="xl:w-1/3 lg:w-1/2 md:w-1/2 lg:mb-0 mb-2 w-full">
                        <input
                            type="text"
                            value={(searchTerm !== 'null') ? searchTerm : ''}
                            onChange={handleUserSearch}
                            className="shadow appearance-none border-0 rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Search by user name..."
                        />
                    </div>
                    <div>
                    <button className="p-2 px-4 border  mr-2 rounded text-white  bg-blue-600"
                             onClick={() => exportData()}>    <span className="fa fa-file-excel-o text-white pr-2"></span> Export 
                 
                    </button>
                    <button className="p-2 px-4 border  rounded  float-right bg-white"
                            onClick={onClickFilter}> Filter 
                    <span className="fa fa-angle-down text-gray-500 pl-3"></span>
                    </button>
                   
                    </div>
                </div>
                {(showResults) && (
                    <div className="card p-5 mb-5 clear-both">
                    <form>
                    <div className="flex  flex-wrap">
                    <div className="xl:w-1/4 md:w-1/3 w-full md:pr-5">
                        <div className="form-group mb-6">
                            <label className="formBlock mb-2 py-2">From { startDateValue ? moment(startDateValue).format('MM-DD-YYYY') : ""}</label>
                            <DatePicker
                               className="w-full"
                               id="start_date"
                               name="start_date"
                               selected={(startDateValue !== '') ? startDateValue : ''}
                               onChange={(date) => setStartDateValue(date)}
                               shouldCloseOnSelect={false}
                               placeholderText='Select Start Date'
                             /> 
                        </div>
                    </div>   
                    <div className="xl:w-1/4 md:w-1/3 w-full md:pr-5">
                        <div className="form-group mb-6 ">
                            <label className="formBlock mb-2 py-2">To {endDateValue ? moment(endDateValue).format('MM-DD-YYYY') : ""}</label>
                            <DatePicker
                               className="w-full"
                               id="end_date"
                               name="end_date"
                               selected={(endDateValue !== '') ? endDateValue : ''}
                               onChange={(date) => setEndDateValue(date)}
                               shouldCloseOnSelect={false}
                               placeholderText='Select End Date'
                             /> 
                        </div>
                    </div>                     
                    <div className=" w-full flex justify-end">
                                    <button
                                        onClick={(e) => handlePaymentFilter(e)}
                                        className="p-2 px-6 hover:bg-blue-900 sm:w-36 w-full bg-blue-700 text-white clear-both float-right rounded">
                                        Apply Filter
                                    </button>
                                    <button
                                        onClick={(e) => handleResetFilter(e)}
                                        className="p-2 px-2 text-blue-500 text-white clear-both ml-2 float-right rounded">
                                        Clear All
                                    </button>
                    </div>

                    </div>
                    </form>
                    </div>
                )}
                <div className="card">
                    <div className="overflow-x-auto w-full whitespace-nowrap">
                    <table className="table-auto tr-even:bg-grey-light striped w-full">
                        <thead className="thead-dark border-b">
                            <tr className="text-gray-600">
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">User Name</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Transaction ID</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Transaction Date</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Expiry Date</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Subscription Plan</button></div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center"><button className="font-normal">Amount</button></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {payments_list?.length > 0 ? (
                            payments_list.map((payment, index) => {
                              const txn_date = moment(payment.validFrom).format('MM-DD-YYYY');
                              const expiry_date = moment(payment.validTo).format('MM-DD-YYYY');
                            return (                           
                            <tr className="border-b text-gray-500" data-index="0">
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{payment.user.fullName}</td>
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{payment.transactionId}</td>
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{txn_date}</td>
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">{expiry_date}</td>
                                <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">
                                {payment.subscriptionId==1 ? "Monthly" : "Annual" }
                                </td>
                                <td className="p-4 dark:text-gray-300 dark:border-gray-500">${payment.amount}</td>
                                <td className="px-4 py-2">
                                </td>
                            </tr>
                                   );
                            })
                            ) : (
                                <tr className="border-b">
                                    <td className="py-5 text-center" colSpan="12">
                                        No Payments Found
                                    </td>
                                </tr>
                            )}                                                                       
                        </tbody>
                    </table>
                        <Pagination page={page} pages={pages} changePage={setPage}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
       
    );
}
import axios from 'axios';
import {toast} from "react-toastify";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {authHeader, handlingNonauthErrors, handlingAuthErrors, destroyTokenDetails, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";

const MySwal = withReactContent(Swal);

export const dashboardCountAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_DASHBOARD_COUNT_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl
    }).then((res) => {
        dispatch({type: 'GET_DASHBOARD_COUNT_SUCCESS', payload: {dashboard_count: res.data.dashboard_count[0]}
                });
    }).catch((err) => {
        dispatch({type: 'GET_DASHBOARD_COUNT_FAILED', payload: err});
        handlingAuthErrors(err)
    });
}

export const contactsListAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_CONTACT_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl
    }).then((res) => {
        dispatch({type: 'GET_CONTACT_LIST_SUCCESS', payload: {data: res.data.contactsList, 
                                                              totalItems: res.data.totalPages}
                });
    }).catch((err) => {
        dispatch({type: 'GET_CONTACT_LIST_FAILED', payload: err});
        handlingAuthErrors(err)
    });
}

export const paymentListAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_PAYMENT_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl
    }).then((res) => {
        dispatch({type: 'GET_PAYMENT_LIST_SUCCESS', payload: {data: res.data.paymentList.rows, 
                                                              totalItems: res.data.totalPages}
                });
    }).catch((err) => {
        dispatch({type: 'GET_PAYMENT_LIST_FAILED', payload: err});
        handlingAuthErrors(err)
    });
}

export const tripListAction = (baseUrl) => dispatch => {
    dispatch({type: 'GET_TRIPS_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl+"&isAdmin=1"
    }).then((res) => {
        dispatch({type: 'GET_TRIPS_LIST_SUCCESS', payload: {data: res.data.userTrips.rows, 
                                                              totalItems: res.data.totalPages}
                });
    }).catch((err) => {
        dispatch({type: 'GET_TRIPS_LIST_FAILED', payload: err});
        handlingAuthErrors(err)
    });
}

export const paymentReportDownloadAction = (baseUrl,reportName) => dispatch => {

    dispatch({type: 'EXPORT_PAYMENT_REQUEST'})
    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null;
    axios.get(baseUrl, {
        headers:
            {
                "Authorization" : `Bearer ${currentToken}`,
                'Content-Disposition': "attachment; filename=template.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        responseType: 'arraybuffer',
    }).then((res) => {
            dispatch({type: 'EXPORT_PAYMENT_SUCCESS'})
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${reportName}-${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((err) => {
                dispatch({type: 'EXPORT_PAYMENT_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const emergencyContactsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_EMERG_CONTACTS_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl
    }).then((res) => {
        dispatch({type: 'GET_EMERG_CONTACTS_SUCCESS', payload: {userEmergContacts: res.data.userEmergContacts,
                                                                userDetail: res.data.userDetail, medicalInfo: res.data.medicalInfo
                                                               }
                });
    }).catch((err) => {
        dispatch({type: 'GET_EMERG_CONTACTS_FAILED', payload: err});
        handlingAuthErrors(err)
    });
}

export const policyContentAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_POLICY_CONTENT_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl
    }).then((res) => {
        dispatch({type: 'GET_POLICY_CONTENT_SUCCESS', payload: {policyContent: res.data.data
                                                               }
                });
    }).catch((err) => {
        dispatch({type: 'GET_POLICY_CONTENT_FAILED', payload: err});
        handlingAuthErrors(err)
    });
}

export const getPolicyContentAction = (baseUrl) => dispatch => {

    dispatch({type: 'FETCH_POLICY_CONTENT_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl
    }).then((res) => {
        dispatch({type: 'FETCH_POLICY_CONTENT_SUCCESS', payload: {policyContent: res.data.data
                                                               }
                });
    }).catch((err) => {
        dispatch({type: 'FETCH_POLICY_CONTENT_FAILED', payload: err});
        handlingAuthErrors(err)
    });
}

export const staticPageCreateEditAction = (baseUrl, method, formData, _form, getUrl, getId) => dispatch => {

    dispatch({type: 'GET_STATIC_PAGE_REQUEST'})
    let frm = jQuery(_form);
    axios({
        url: baseUrl, data: formData, method: method,
        headers: authHeader(),
    }).then((response) => {
            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                window.location.href = "/policy"
                dispatch({type: 'CREATE_POLICY_CONTENT_SUCCESS', payload: response.data.data})
            }, 1000);
        }).catch((err) => {
                dispatch({type: 'GET_POLICY_CONTENT_FAILED'})
                if (getId) {
                    dispatch(getPolicyContentAction(getUrl));
                }
                handlingAuthErrors(err);
            }
        )
}

export const contactDeleteAction = (baseUrl, listUrl, data) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_CONTACT_LIST_REQUEST'})
                axios.post(baseUrl, data, {
                    headers: authHeader(),
                }).then((res) => {
                        dispatch({type: 'DELETE_CONTACT_SUCCESS'})
                        window.location.reload();
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        });
                        dispatch({type: 'GET_CONTACT_LIST_REQUEST'})
                        dispatch(contactsListAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_CONTACT_FAILED'})
                            if (err.response.status === 401) {
                                destroyTokenDetails()
                                window.location.href = "/"
                            }
                            toast.error(err.response.data.message, {
                                duration: 2000,
                                position: 'top-right',
                            })
                        }
                    )
            }
        }
    )
}